export const seo = {
  url: 'quote',
  title: {
    en: 'Quote | International and domestic transport',
    es: 'Cotización | Transporte internacional y nacional',
    ro: 'Citat | Transport intern si international',
  },
  desc: {
    en: 'Make an inquiry for a quote for international transport and domestic transport. Gain an experienced logistics partner. Work with a Polish freight forwarder.',
    es: 'Solicite cotización de transporte internacional y transporte nacional. Obtenga un socio logístico experimentado. Trabajar con un transitario polaco',
    ro: 'Faceți o întrebare pentru o oferta pentru transport internațional si transport intern. Obțineți un partener logistic cu experiență. Lucrați cu un expeditor polonez.',
  },
  keywords: [
    'omida',
    'logistics',
    'transport',
    'quote',
    'quotation',
    'forwarding',
    'shipping',
  ],
}

export const intro = {
  title: {
    en: 'Transport Quote',
    es: 'Cotización de transporte',
    ro: 'Cotat de transport',
  },
  desc: {
    en: 'Make an inquiry for a quote for international transport and domestic transport. Gain an experienced logistics partner. Work with a Polish freight forwarder.',
    es: 'Solicite cotización de transporte internacional y transporte nacional. Obtenga un socio logístico experimentado. Trabajar con un transitario polaco',
    ro: 'Faceți o întrebare pentru o oferta pentru transport internațional si transport intern. Obțineți un partener logistic cu experiență. Lucrați cu un expeditor polonez.',
  },
}

export const main = {
  title: {
    en: 'Looking for transport? Make a free quotation request for the transport service.',
    es: '¿Estás buscando transporte? Realice una consulta gratuita sobre el precio del servicio de transporte.',
    ro: 'Cauți transport? Faceți o cerere de ofertă gratuită pentru serviciul de transport.',
  },
  texts: [
    {
      en: '<span>Choose an experienced forwarding company. We have completed over <strong>2 million shipments</strong> in Europe and around the world. Specify the place and date of loading and unloading your goods. Optionally, enter the parameters of individual loads. In the message, you can describe the assumptions and needs of the order. Our forwarders will contact you by e-mail or phone <strong> as soon as possible </strong>.</span>',
      es: '<span> Elija una empresa de transporte con experiencia. Hemos completado más de <strong> 2 millones de envíos </strong> en Europa y en todo el mundo. Especifique el lugar y la fecha de carga y descarga de sus mercancías. Opcionalmente, ingrese los parámetros de las cargas individuales. En el mensaje, puede describir los supuestos y necesidades del pedido. Nuestros transitarios se pondrán en contacto con usted por correo electrónico o por teléfono <strong> lo antes posible </strong>. </span>',
      ro: '<span> Alegeți o companie de transport cu experiență. Am completat mai mult de <strong> 2 milioane de expedieri </strong> în Europa și în tot lumea. Specificați locația și la data de încărcare și descărcare a mărfurilor de mai susFacultativ, introduceți parametrii pentru încărcăturile individuale. În mesaj, puteți descrie presupusele și nevoile comenzii. Transportatorii noștri de marfă vor în contact cu dvs. prin e-mail sau prin telefon <strong>cât mai curând posibil</strong>.</span>',
    },
  ],
}
