import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/quote'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import { useFormContext } from '../context/form.context'
import FormDynamic from '../components/FormDynamic'
import { SectionBackground } from '../components/Section'

const Quote = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/quote.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      en: 'Query now',
      es: 'Hacer una consulta',
      ro: 'Întrebați acum',
    },
    action: () => setFormOpen(true),
  }

  const buttonSecond = {
    text: {
      en: 'How it works?',
      es: '¿Cómo funciona?',
      ro: 'Cum funcționează?',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        imageUrl={image.publicURL}
        url={seo.url}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main data={main} h={1} />
      <SectionBackground mobile top pbXL>
        <FormDynamic notypes override='QUOTE' />
      </SectionBackground>
    </Layout>
  )
}

export default Quote
